

























import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ClusterMarketsService, { ClusterMarketsServiceS } from '@/modules/cluster/cluster-markets.service';
import CustomDropdown from '@/modules/common/components/ui-kit/custom-dropdown.vue';

@Component({
    components: {
        CustomDropdown,
    },
})
export default class ExcelBtn extends Vue {
    @Inject(ClusterMarketsServiceS)
    private clusterMarketsService!: ClusterMarketsService;

    @Prop({
        type: Boolean,
        default: false,
    })
    isCluster!: boolean;

    public items = [
        {
            link: 'download-excel',
            text: 'Download',
        },
        {
            link: 'scheduled-reports',
            text: 'Schedule',
        },
    ];

    async downloadClusterReport() {
        await this.clusterMarketsService.downloadExcel();
    }
}
