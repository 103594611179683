







import { Component, Prop, Vue } from 'vue-property-decorator';
import MarketsGraphTypeChanger from '@/modules/markets/components/graph/markets-graph-type-changer.vue';
import ProviderCard from '@/modules/common/components/ui-kit/provider-card.vue';

@Component({
    components: {
        ProviderCard,
        MarketsGraphTypeChanger,
    },
})
export default class MarketsGraphHeader extends Vue {
    @Prop({
        type: String,
        required: true,
    })
    provider!: string;
}
