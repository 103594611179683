













import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';

@Component
export default class MarketsGraphRangeLabel extends Vue {
    @Inject(HotelsServiceS) protected hotelsService!: HotelsService;
    @Inject(UserServiceS) protected userService!: UserService;

    disableCurrentHotel: boolean = false;

    get currentHotelName(): string | null {
        const { currentHotelId } = this.userService;
        if (!currentHotelId) {
            return null;
        }
        return this.hotelsService.getHotelName(currentHotelId);
    }

    get currentHotelId(): number| null {
        return this.userService.currentHotelId;
    }

    toggleGraphLines() {
        this.disableCurrentHotel = !this.disableCurrentHotel;
        this.$emit('toggleGraphData', this.disableCurrentHotel);
    }
}
