































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { ChartOptions, ChartDataSets } from 'chart.js';
import Day from '@/modules/common/types/day.type';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import MarketsService, { MarketsServiceS } from '@/modules/markets/markets.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import MarketsGraphRangeLabel from './markets-graph-range-label.vue';
import MarketsPreviewTooltip from '../calendar/markets-preview-tooltip.vue';

@Component({
    components: {
        CustomGraph,
        MarketsGraphRangeLabel,
        LoaderWrapper,
        MarketsPreviewTooltip,
    },
})
export default class MarketsGraphRange extends Vue {
    @Inject(MarketsServiceS) protected marketsService!: MarketsService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(UserServiceS) private userService!: UserService;

    private disabledChart: boolean = false;

    @Prop({
        type: String,
        required: true,
    })
    provider!: string;

    tooltipDay: Day | null = null;
    tooltipFocusElement: HTMLElement | null = null;

    showDayMarketsPopup(day: string) {
        return this.$router.push(`graphs/day-markets/${this.parseLabel(day)}/${this.provider}`);
    }

    parseLabel(label: string | null) {
        return label ? String(parseInt(label, 10)) : null;
    }

    get hotelId() {
        const { currentHotelId } = this.userService;

        return +this.$route.params.hotelId || currentHotelId;
    }

    get isLoading() {
        return this.marketsService.isLoading;
    }

    get chartData(): { labels: (Day | string)[][], datasets: ChartDataSets[] } {
        const { provider } = this;
        const { days, year, month } = this.documentFiltersService;
        const { currentHotelId } = this.userService;
        const labels = [] as [Day, string][];
        const myRates = [] as (number | null)[];

        if (!days || !currentHotelId) {
            return {
                labels: [],
                datasets: [],
            };
        }

        const compsetMinMaxPositions = this.marketsService
            .minMaxPositions(currentHotelId, provider);

        days.forEach(day => {
            const myRate = this.marketsService.myPosition(day, this.provider);
            const label = this.$t(`dayShort.${new Date(year, month, day).getDay()}`).toString();

            myRates.push(myRate);
            labels.push([day, label]);
        });

        return {
            labels,
            // @ts-ignore
            datasets: [
                {
                    label: String(currentHotelId),
                    data: myRates,
                    borderColor: '#2081AC',
                    pointRadius: 3,
                    borderWidth: 3,
                    pointBackgroundColor: 'white',
                    lineTension: 0,
                    borderJoinStyle: 'round',
                    hidden: this.disabledChart,
                },
                {
                    label: 'Min',
                    data: compsetMinMaxPositions ? compsetMinMaxPositions.min : [],
                    borderDash: [0, 1],
                    backgroundColor: '#E9F7FD',
                    fill: '2',
                    pointRadius: 0,
                    borderColor: '#79CFF3',
                    lineTension: 0,
                    borderJoinStyle: 'round',
                },
                {
                    label: 'Max',
                    data: compsetMinMaxPositions ? compsetMinMaxPositions.max : [],
                    borderDash: [0, 1],
                    borderColor: '#79CFF3',
                    lineTension: 0,
                    borderJoinStyle: 'round',
                },

            ],
        };
    }

    get options(): ChartOptions {
        return {
            maintainAspectRatio: false,
            tooltips: { enabled: false },
            elements: {
                line: {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
                point: {
                    radius: 0,
                },
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: true,
                        borderDash: [0, 1],
                        offsetGridLines: true,
                        color: '#ECF1F5',
                    },
                    ticks: {
                        callback(value) {
                            return value < 10 ? `0${value}` : value;
                        },
                    },
                }],
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: 'Position',
                    },
                    gridLines: {
                        display: true,
                        offsetGridLines: true,
                        borderDash: [0, 4],
                        color: '#ECF1F5',
                        zeroLineWidth: 0,
                    },
                    offset: true,
                    ticks: {
                        autoSkip: true,
                        padding: 10,
                        reverse: true,
                        callback(value) {
                            return `#${value}`;
                        },
                    },
                }],
            },
            legend: {
                display: false,
            },
            plugins: {
                filler: {
                    propagate: true,
                },
            },
        };
    }

    toggleGraphData(toggle: boolean) {
        this.disabledChart = toggle;
    }

    setTooltipElement(el: HTMLElement) {
        this.tooltipFocusElement = el;
    }

    setCurrentDay(rawString: string) {
        this.tooltipDay = this.parseLabel(rawString) as unknown as Day;
        this.tooltipDay = this.tooltipDay && +this.tooltipDay as Day;
    }
}
