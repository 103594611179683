




import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import MarketsFiltersService, { MarketsFiltersServiceS } from '@/modules/markets/markets-filters.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';

@Component({
    components: { CustomSelect },
})
export default class ProviderTypeFilter extends Vue {
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(MarketsFiltersServiceS) private marketsFiltersService!: MarketsFiltersService;
    @Inject(ProvidersServiceS) private providersService!: ProvidersService;

    get provider() {
        return this.marketsFiltersService.currentProvider;
    }
    set provider(value) {
        this.marketsFiltersService.saveProvider(value);
    }

    getProviderLabel(providerName: string) {
        const { data } = this.providersService;

        if (!data) {
            return providerName;
        }

        const provider = data.find(currentProvider => currentProvider.name === providerName);

        if (!provider) {
            return '';
        }

        return provider.label || provider.name || '';
    }

    get providerItems(): Item[] {
        if (!this.compsetsService.currentCompset) {
            return [];
        }

        return this.compsetsService.currentCompset.marketProviders.map(provider => ({
            value: provider,
            name: this.getProviderLabel(provider),
        }));
    }
}
