










import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import MarketsFiltersService, { MarketsFiltersServiceS } from '@/modules/markets/markets-filters.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';

@Component({
    components: { CustomSelect },
})
export default class PosMarketFilter extends Vue {
    @Inject(MarketsFiltersServiceS) private marketsFiltersService!: MarketsFiltersService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;

    get pos() {
        this.marketsFiltersService.verifyPos();
        return this.marketsFiltersService.storeState.settings.pos;
    }
    set pos(value) {
        this.marketsFiltersService.pos = value;
    }
    get posItems(): Item[] {
        return this.documentFiltersService.getPosItems(null);
    }
}
